import React from "react";
import "./CommentCard.css"

export default function CommentCard(props){
    return(
        <div className="comments-container">
            <div className="com-top">
                <img src={props.profile_pic} alt="" />
                <h4>{props.name}</h4>
            </div>
            <div className="com-content">
                <p>{props.content}</p>
            </div>
        </div>
    )
}