import React from "react";
import "./CommentBox.css"
import CommentCard from "./CommentCard/CommentCard";
import CommentDetails from "./Comments.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

export default function CommentBox(props){
    return(
        <div className="comments-main">
            <div className="com-main-top">
                <div className="close" onClick={()=>props.setComment(!props.comment)}>
                    <FontAwesomeIcon icon={faX} className="com-close-icon"/>
                </div>
                <h2>Comments</h2>
            </div>
            <form action="">
                <textarea name="" id="" placeholder="Share your thoughts"></textarea>
                <button type="submit">Send</button>
            </form>
            {CommentDetails.map((comment) => (
                <CommentCard
                    key = {comment.id}
                    profile_pic = {comment.profile_pic}
                    name = {comment.name}
                    content = {comment.content}
                />
            ))}
        </div>
    )
}