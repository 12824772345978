import React,{useState} from "react"
import "./UserCard.css"
import user from "../../images/user.png"
import AccountActions from "../../modals/AccountActions/AccountActions"
import ProfileImage from "../ProfileImage/ProfileImage"
let user_data = localStorage.getItem('user')
user_data = JSON.parse(user_data)
export default function UserCard(props) {
    
    const gmail = "abcdefghijklmnopqrstuvwxyz123@gmail.com"
    const[actions,setActions] = useState(false)

    return (
        <div 
            className="userCard-container" 
            onClick={() => setActions(true)} 
        >
            <div className="left">
                <div className="image">
                    <ProfileImage img={user_data.logo ? user_data.logo : `https://picsum.photos/150/150?random=2`}/>
                </div>
            </div>
            <div className="right">
                <p className="name">{props.user.name}</p>
                <p className="email">{props.user.email}</p>
            </div>
            {actions && <AccountActions setActions={setActions}/>}
       
        </div>
    )
}