import React, { useState } from "react";
import './VendorPostForm.css';
import FileInput from "../../input/fileInput/FileInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import { BASE_URL } from "../../api/config";
import Swal from "sweetalert2";

export default function VendorPostForm(props) {
    const { getUser } = useAuth();
    const user = getUser();

    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [mediaType, setMediaType] = useState(null);
    const [source, setSource] = useState(null);
    const [sourceURLs, setSourceURLs] = useState([]);
    const [mediaFiles, setMediaFiles] = useState([]);
    const [imgSuccess, setImgSuccess] = useState(false);

    const handleMediaTypeChange = (e) => {
        const selectedType = e.target.value;

        if (selectedType === "upload") {
            setMediaType("upload");
            setSource(null);
            setSourceURLs([]);
        } else if (selectedType === "instagram") {
            setMediaType(null);
            setSource("instagram");
            setSourceURLs([]);  
        }
    };

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 0) {
            setImgSuccess(true);
            setMediaFiles(files); 
        }
    };

    const handleFormSubmission = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('userid', user.userid);
        formData.append('name', user.name);
        formData.append('title', title);
        formData.append('description', desc);
        formData.append('media_type', mediaType);
        formData.append('source', source);
        formData.append('source_urls', JSON.stringify(sourceURLs));

        if (mediaType === "upload" && mediaFiles.length > 0) {
            mediaFiles.forEach((file) => {
                formData.append("media_files", file);
            });
        }

        try {
            const res = await axios.post(`${BASE_URL}/vendor_post`, formData, {
                headers: {
                    "Content-Type": 'multipart/form-data',
                    "Authorization": `Bearer ${token}`
                }
            });

            if (res.data.status === 201) {
                console.log('Post successful', res.data);
                Swal.fire({
                    title: "Success!",
                    icon: "success",
                    text: "Your post has been successfully uploaded",
                    confirmButtonColor: '#125488'
                });
            } else {
                console.error('Post failed', res.data);
            }
        } catch (error) {
            console.error('An error occurred', error);
        }
    };

    return (
        <div className="postForm-container">
            <FontAwesomeIcon icon={faX} className="close-icon" onClick={() => props.setToggleForm(!props.toggleForm)} />
            <form onSubmit={handleFormSubmission}>
                <h1>Share your latest work</h1>

                <div className="ip">
                    <label htmlFor="title">Title</label>
                    <input 
                        className="form-input" 
                        type="text" 
                        required
                        placeholder="Event Name" 
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)} 
                    />
                </div>

                <div className="ip">
                    <label htmlFor="desc">Description</label>
                    <textarea 
                        className="form-textarea" 
                        type="text" 
                        placeholder="Add a description" 
                        required
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                    />
                </div>

                <div className="ip">
                    <label htmlFor="Media">Media Type</label>
                    <select value={mediaType || ""} onChange={handleMediaTypeChange} required>
                        <option value="" disabled>Select an option</option>
                        <option value="upload">Upload</option>
                        <option value="instagram" disabled>Instagram</option>
                    </select>
                </div>

                {mediaType === "upload" && (
                    <div className="formIp">
                        <FileInput
                            handleImageUpload={handleImageUpload}
                            multiple={true}
                            required={true}
                            imgSuccess={imgSuccess}
                        />
                    </div>
                )}

                <div className="form-btns">
                    <button type="reset" onClick={() => props.setToggleForm(!props.toggleForm)}>Cancel</button>
                    <button type="submit">Save</button>
                </div>
            </form>
        </div>
    );
}
