import React, { useState } from "react";
import './CreateEvent.css'
import categoryData from '../../components/CategorySlider/Category.json'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faX } from "@fortawesome/free-solid-svg-icons";

export default function CreateEvent(props){
    
    const[accordion,setAccordion] =  useState(false)

    return(
        <div className="create-main">
            <FontAwesomeIcon icon={faX} className="close-icon" onClick={props.toggleCreateEvent} />
            <div className="create-container">
                <h1>Add a New Event</h1>
                <form action="">
                    <div className="ip">
                        <label htmlFor="">Title</label>
                        <input className="form-input" type="text" placeholder="Event Name" required />
                    </div>
                    <div className="ip">
                        <label htmlFor="">Category</label>
                        <select >
                            <option disabled selected>Choose a catgory</option>
                            {categoryData.map((item,index) =>(
                                <option key={index}>{item.name}</option>

                            ))}
                        </select>
                    </div>
                    <div className="ip">
                        <label htmlFor="">Description</label>
                        <textarea className="form-textarea" type="text" placeholder="Add a description" required/>
                    </div>
                    <div className="ip">
                        <label htmlFor="">Location</label>
                        <input className="form-input" type="text" placeholder="Enter your Location" required/>
                    </div>
                    <div className="ip">
                        <label htmlFor="">Date</label>
                        <input className="form-input" type="date" required/>
                    </div>
                    <div className={`add-details ${accordion ? "expand" : ""}`}>
                        <div className="accordion-header" onClick={() => setAccordion(!accordion)}>
                            <p>Additional Details</p>
                            <FontAwesomeIcon icon={faChevronUp} className={`${accordion ? "up" : "down"}`}/>
                        </div>
                        {accordion &&
                            <div className="accordion">
                                <div className="ip">
                                    <label htmlFor="">Expected Crowd</label>
                                    <input className="form-input" type="text" placeholder="Expected Crowd" required/>
                                </div>
                                <div className="ip">
                                    <label htmlFor="">Additional Requirements</label>
                                    <textarea className="form-textarea" type="text" placeholder="Enter any additional Requirements about the event" required/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="form-btns">
                        <button type="reset" onClick={props.toggleCreateEvent}>Cancel</button>
                        <button type="submit">Save</button>
                    </div>
                </form>
            </div>
        </div>
    )
}