import React, { useState } from "react";
import './EventExpansion.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faHeart } from "@fortawesome/free-regular-svg-icons";
import { faArrowUpFromBracket, faX } from "@fortawesome/free-solid-svg-icons";
import img1 from "../../images/trial/img1.jpg";
import img2 from "../../images/trial/img2.jpeg";
import img3 from "../../images/trial/img3.jpeg";
import CommentBox from "../../components/CommentsBox/CommentBox";
let user_data = localStorage.getItem('user')
user_data = JSON.parse(user_data)

export default function EventExpansion(props) {
    const [selectedImage, setSelectedImage] = useState(props.images[0]);
    const [like, setLike] = useState(false);
    const [comment, setComment] = useState(false);

    return (
        <div className="evEx-main">
            <FontAwesomeIcon icon={faX} className="close-icon" onClick={props.onClose} />
            <div className="evEx-container">
                
                {props.type === "vendor_card" && (
                    <div className="nav-top">
                        <div className="left">
                            <img src={props.profilePic} alt="" className="profilePic" />
                            <p className="userName">{props.company_name}</p>
                        </div>
                        <div className="right">
                            <button className="connect-btn">Connect +</button>
                        </div>
                    </div>
                )}

                <div className="content">
                    <div className="header">
                        <h1 className="event-title">{props.title}</h1>
                        <p className="event-location">{user_data? null : props.location}</p>
                    </div>

                    <div className="imageSection">
                        <div className="mainSection">
                            <img src={selectedImage} alt="" className="main-img" />
                            <ul className="btns">
                                <li className={`like ${like ? "liked" : ""}`} onClick={() => setLike(!like)}>
                                    <FontAwesomeIcon icon={faHeart} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faCommentDots} onClick={() => setComment(!comment)} />
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faArrowUpFromBracket} />
                                </li>
                            </ul>
                        </div>

                        <div className="imageList">
                            {props.images.map((img, index) => (
                                <img
                                    key={index}
                                    src={img}
                                    alt=""
                                    className={`img ${selectedImage === img ? "selected" : ""}`}
                                    onClick={() => setSelectedImage(img)}
                                />
                            ))}
                        </div>
                    </div>

                    <div className="description">
                        <h3>Description</h3>
                        <p className="desc">{props.description}</p>
                    </div>
                    {props.type === "vendor_card" && (
                    <hr></hr>
                )}

                    {props.type === "vendor_card" && (
                        <div className="related">
                            <h3>More from {props.company_name}</h3>
                            <div className="flex">
                                <img src={img1} alt="" />
                                <img src={img2} alt="" />
                                <img src={img3} alt="" />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {comment && <CommentBox setComment={setComment} comment={comment} />}
        </div>
    );
}
