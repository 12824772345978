import React from "react";
import './FileInput.css'
import { faCircleCheck, faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FileInput(props){
    return (
        <div className="fileInput">
            <label htmlFor="" className="main-label">Upload Company Document To Verify</label>
            <div className="icon">
                <FontAwesomeIcon className="imgIcon" icon={faImage}/>
                <label htmlFor="">Upload An Image</label>
                <FontAwesomeIcon icon={faCircleCheck} className={`checkIcon ${props.imgSuccess ? "success" : ""}`}/>
            </div>
            <input 
                type="file" 
                accept="image/*" 
                multiple={props.multiple} 
                required={props.required}
                onChange={(e) => props.handleImageUpload(e)}
            />
        </div>
    )
}