import React, { useEffect, useState } from "react";
import './VendorSignUp.css'
import { BASE_URL } from "../../../api/config";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google';
import LoginInput from "../../../input/loginInput/LoginInput";
import PasswordInput from "../../../input/passwordInput/PasswordInput";
import FileInput from "../../../input/fileInput/FileInput";
import Dropdown from "../../../input/dropdown/Dropdown";
import PNG from "../../../images/signPNG.png"
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";
import Swal from "sweetalert2";

export default function VendorSignUpPage(){

    const [err_msg, setErr_msg] = useState('')
    const [brandName , setBrandName] = useState('')
    const [category, setCategory] = useState('')
    const [emailPhone,setEmailPhone] = useState('')
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [img, setImg] = useState([])
    const [location, setLocation] = useState('')
    const [imgSuccess, setImgSuccess] = useState(false)
    

    // Name Validation
    const nameValidation = (name) => {
        if (name.length < 2){
            return "Name should be atleast 2 characters"
        }
        return ""
    }

    // Password Validation
    const validatePassword = (password) => {
        const minLength = 8;
        const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    
        if (password.length < minLength) {
            return "Password must be at least 8 characters long."
        }
        if (!regex.test(password)) {
            return "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
        }
        return ""

    }

    // Email Or Phone Validation
    const emailPhoneValidation = (value) => {
        const regex = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?![. ])|\+?\d{10})$/;
        if (!regex.test(value)) {
            return "Input must be a valid email address or a phone number."
        }
        return ""
    };

    const handleType = (e,validationFn, stateVar, setStateVar) => {
        setStateVar(e.target.value)
        const errorMessage = validationFn(e.target.value)
        setErr_msg(errorMessage)
    }

    const navigate = useNavigate()

    // formData
    const formData = new FormData() 
    formData.append("brandName", brandName)
    formData.append("email", emailPhone)
    formData.append("category", category)
    formData.append("password", password)
    formData.append("location", location)

    if(img){
        formData.append("file", img)
    }


    // Handle Image Upload
    const handleImageUpload = (e) => {
        const file = e.target.files[0]
        if(file){
            setImg(file)
            formData.append("file", file)
            toast.success('Image Uploaded Successfully',{
                theme:"dark",
                hideProgressBar:true,
                autoClose:1000
            }) 
            setImgSuccess(true) 
        }
    }

    // Form Submission
    const handleSubmit = async(e) => {
        e.preventDefault();

        const errorMessage = nameValidation(brandName) || 
                             emailPhoneValidation(emailPhone) || 
                             validatePassword(password) || 
                             (password !== rePassword ? "Passwords do not match" : "")
        
        if (errorMessage) {
            setErr_msg(errorMessage);
            console.log({
                brandName: brandName,
                category: category,
                email: emailPhone,
                password: password,
                file: img
            });
            return
        } else {
            console.log("Registration Success");
            console.log({
                brandName: brandName,
                category: category,
                email: emailPhone,
                password: password,
            });
            console.log(formData)
        }

        try{
            const res = await axios.post(`${BASE_URL}/vendor_register`,formData,{
                headers: {
                    'Content-Type' : 'multipart/form-data'
                }
            })
            console.log("New Response",res)

            if(res.data.status === 201){
                console.log('Registration Success',res.data)
                setErr_msg('Registration Success')
                navigate('/Vendorlogin')

            }else{
                console.error('Login Failed', res.status)
                setErr_msg('Registration failed')
            }
        }catch(error){
            console.error("An error occured",error)
        }
    }

    return(
        <div className="vendor-sign-container">
            <div className="vendor-sign-main">
                <div className="left">
                    <h1>Create a New Account</h1>
                    <form onSubmit={handleSubmit}>
                        <LoginInput 
                            type = {`text`} 
                            label = {`Brand Name`}
                            value = {brandName}
                            onChange = {(e) => handleType(e,nameValidation,brandName,setBrandName)}
                        />
                        
                        <LoginInput
                            className="red" 
                            type = {`text`}
                            label = {`Email/Phone`}
                            value = {emailPhone}
                            onChange = {(e) => handleType(e,emailPhoneValidation,emailPhone,setEmailPhone)}
                        />

                        <Dropdown
                            category = {category}
                            setCategory = {setCategory}
                        />

                        <PasswordInput
                            label = {`Enter Password`}
                            value = {password}
                            onChange = {(e) => handleType(e,validatePassword,password,setPassword)}

                        />
                        <PasswordInput
                            label = {`Re-Enter Password`}
                            value = {rePassword}
                            onChange = {(e) => handleType(e,validatePassword,rePassword,setRePassword)}
                        />
                        <LoginInput 
                            type="text" 
                            label="Location" 
                            value={location} 
                            onChange={(e) => setLocation(e.target.value)} 
                        />
                        <FileInput
                            setImg = {setImg}
                            handleImageUpload = {handleImageUpload}
                            imgSuccess = {imgSuccess}
                        />

                        <div className="TnC">
                            <input type="checkbox" required/>
                            <p>By signing up you agree to our <a href="">Terms of Service</a> and <a href="">Privacy Policy</a></p>
                        </div>
                        <span className="err_msg">{err_msg}</span>
                        <button className="signUp-btn" type="submit">Sign Up</button>
                    </form>


                    <div className="separator"> 
                        <span>Or</span>
                    </div>
                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            console.log(credentialResponse)
                        }}
                        onError={() => {
                            console.log('Login Failed')
                        }}
                        shape={`circle`}
                        ux_mode="popup"
                        text={``}
                    />
                </div>
                <div className="right">
                    <img src={PNG} alt="" />
                    <h2>Already have an account?</h2>
                    <p>We're happy to have you back!</p>
                    <Link to ='/Vendorlogin'>
                        <button className="login-btn">Login</button>
                    </Link>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}