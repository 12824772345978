import React from "react";
import "./AccountActions.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Dashboard from "../../pages/Vendor/Dashboard/Dashboard";
let user_data = localStorage.getItem('user')
user_data = JSON.parse(user_data)
export default function AccountActions(props){

    const navigate = useNavigate()

    const logout = () => {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        navigate('/')  
        console.log('Logged out')
        console.log('User : ',localStorage.getItem('user'))
        console.log('Token : ',localStorage.getItem('token'))
    }
    const dashboard = () => {
        if( user_data.type === 'user'){
            navigate('/')
        }
        else if (user_data.type === 'vendor'){
            navigate('/Dashboard')
        }
    }

        
    return(
        <div className="aa-container" onMouseLeave={() => props.setActions(false) }>
            <div className="row1" onClick={dashboard}>
                <FontAwesomeIcon icon={faUser} className="icon"/>
                <p>Profile</p>
            </div>
            <div className="row2" onClick={logout}>
                <FontAwesomeIcon icon={faArrowRightFromBracket} className="icon"/>
                <p>Logout</p>
            </div>
        </div>
    )
}