import React, { useState } from "react";
import './LoginPage.css'
import { BASE_URL } from "../../api/config";
import { GoogleLogin } from '@react-oauth/google';
import PNG from "../../images/signPNG.png"
import LoginInput from "../../input/loginInput/LoginInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import PasswordInput from "../../input/passwordInput/PasswordInput";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";

export default function LoginPage(){
    
    const {login} = useAuth()

    const navigate = useNavigate()

    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [err,setErr] = useState(false)
    const [err_msg,setErr_msg] = useState('')

    const credentials = {
        email : username,
        password : password
    }


    const formSubmit = async (e) => {
        e.preventDefault()

        try{
            const res = await axios.post(`${BASE_URL}/user_login`,credentials,{
                headers: {
                    'Content-Type' : 'application/json'
                }
            })
            if(res.status === 200){
                console.log('Login Successfull',res.data)
                setErr_msg('Login Success')
                
                // storing access token in local storage
                const token = res.data.data.access_token 
                const userData = {
                    email : res.data.data.email,
                    userid : res.data.data.userid,
                    name : res.data.data.name,
                    location : res.data.data.location,
                    token : token
                }
                login(userData)

                if(token){
                    try{
                        localStorage.setItem('token',token)
                        token ? navigate('/') : console.error('Failed to store token')   
                    }catch(error){
                        console.error("Failed to store token : ",error)
                    }
                }else{
                    console.warn('No access token recieved')
                }
            }else{
                console.error('Login failed', res.status)
                setErr_msg('Login failed')
                setErr(true)
            }
        }catch(error){
            if(error.response?.status === 401){
                setErr_msg('Invalid Credentials')
                setErr(true)
            }else{
                setErr_msg('Something went wrong. Please try again later')
                setErr(true)
            }
        }
    }

    return(
        <div className="login-container">
            <div className="login-main">
                <div className="left">
                    <h1>Welcome Back!</h1>
                    {err ? <span className="er_msg">{`* ${err_msg} *`}</span> : "" }
                    <form action="" onSubmit={formSubmit}>
                        <div className="input">
                            <FontAwesomeIcon icon={faUser} className="ip-icon"/>
                            <LoginInput 
                                label = {`Email/Phone Number`}
                                value = {username}
                                onChange = {e => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="input">
                            <FontAwesomeIcon icon={faLock} className="ip-icon"/>
                            <PasswordInput 
                                label={`Password`}
                                value = {password}
                                onChange = {e => setPassword(e.target.value)}
                            />
                        </div>
                        <Link to = '/ForgotPassword'>Forgot Password?</Link>       
                        <button className="login-btn" type="submit">Login</button>
                    </form>
                    <div className="separator"> 
                        <span>Or</span>
                    </div>

                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            console.log(credentialResponse)
                        }}
                        onError={() => {
                            console.log('Login Failed')
                        }}
                        shape={`circle`}
                        ux_mode="popup"
                        text={``}
                        useOneTap={true}
                        auto_select={true}
                    />
                </div>
                <div className="right">
                    <img src={PNG} alt="" />
                    <h2>Don't have an account?</h2>
                    <p>Get started by creating a new account</p>
                    <Link to = '/SignUp' href="">
                        <button className="register-btn">Register</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}