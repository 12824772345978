import React, { useEffect, useState } from "react";
import "./Dropdown.css"
import { BASE_URL } from "../../api/config";
import axios from "axios";

export default function Dropdown(props){
    const[category,setCategory] = useState([])

    useEffect(()=> {
        const getCategories = async () => {
            try{
                const res = await axios.get(`${BASE_URL}/list_data?table_name=Categories`)
                if(res.status===200){
                    console.log('Categories',res.data)
                    setCategory(res.data.data)
                }
            }catch(error){
                console.error('An error occured',error)
            }
        };
        getCategories()
    },[]);


    return(
        <div className="spclDropDown">
            <select name="" id="" onChange={(e) => props.setCategory(e.target.value)} required>
                <option disabled selected>Choose a category</option>
                {category.map((item,index) => (
                    <option key={item.CategoryID} value={item.CategoryID}>{item.Description}</option>
                ))}
            </select>   
        </div>
    )
}