import React, { useState } from "react";
import './Dashboard.css'
import Navbar from "../../../components/Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Feed from "./Feed/Feed";
import CardDetails from "./Card Details/CardDetails";
import Profile from "./Profile/Profile";
import { Link } from "react-router-dom";
let user_data = localStorage.getItem('user')
user_data = JSON.parse(user_data)
export default function Dashboard(){
    const[content,setContent] = useState("Feed")
    const[active,setActive] = useState("Feed")
    const[cname,setCName] = useState(user_data.name)

    return(
        <div className="dashboard-main">
            <Navbar/>
            <div className="backButton">
                <FontAwesomeIcon icon={faChevronLeft}/>
                <Link to='/Eventlisting'>Event Listing</Link>
            </div>
            <div className="info">
                <img src={user_data.logo}
                     alt="Image" 
                     className="profile"
                />
                <h3>{cname}</h3>
            </div>
            <div className="menu">
                <ul>
                    <li onClick={() => {setContent("Feed"); setActive("Feed")}}>
                        <a  href="#" 
                            className={`${active === "Feed" ? "active" : ""}`}>
                                Feed
                        </a>
                    </li>
                    <li onClick={() => {setContent("CardDetails"); setActive("CardDetails")}}>
                        <a  href="#" 
                            className={`${active === "CardDetails" ? "active" : ""}`}>
                                Card Details
                            </a>
                        </li>
                    <li onClick={() => {setContent("Profile"); setActive("Profile")}}>
                        <a  href="#" 
                            className={`${active === "Profile" ? "active" : ""}`}>
                                Profile
                        </a>
                    </li>
                </ul>
            </div>
            <div className="content">
                {content === "Feed" ? <Feed/> 
                    : 
                content === "CardDetails" ? <CardDetails/>
                    :
                content === "Profile" ? <Profile
                                            cname={cname}
                                            setCName={setCName}
                                        />
                    :
                "An error Occured"
                }
            </div>

        </div>
    )
}