import React, { createContext, useContext } from "react";

const AuthContext = createContext()

export const AuthProvider = ({children}) => {
    const getUser = () => {
        const storedUser = localStorage.getItem("user")
        return storedUser ? JSON.parse(storedUser) : null
    }

    const login = (userData) => {
        console.log("asdas",userData)
        localStorage.setItem("user", JSON.stringify(userData))
    }

    return(
        <AuthContext.Provider value={{getUser, login}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)