import React, { useEffect,useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Slider from "../../components/CategorySlider/Slider";
import Cards from "../../components/Cards/Cards";
import './FrontPage.css'
import FeedDetails from './Feeds.json';
import EventExpansion from "../../modals/EventExpansion/EventExpansion";
import axios from "axios";
import { BASE_URL } from "../../api/config";

export default function FrontPage(){

    // function and state for event expansion
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [feedData, setFeedData] = useState([]);
    useEffect(() => {
        const fetchVendorCards = async () => {
            try {
                const response = await axios.post(`${BASE_URL}/get_vendor_card`);
                if (response.data.status === 200) {
                    console.log("Vendor Cards Data:", response.data.data);
                    setFeedData(response.data.data);
                } else {
                    console.error("Failed to fetch vendor cards:", response.data);
                }
            } catch (error) {
                console.error("Error fetching vendor cards:", error);
            }
        };

        fetchVendorCards();
    }, []);
    const closeEventExpansion = () => {
        setSelectedEvent(null); 
    };

    return(
        <div className="f-container">
            <Navbar />
            <Slider/>
            <div className="grid">
                {feedData.map((item,index) => (
                    console.log(item.Logo),
                    <Cards  
                        key={index}
                        mainpic={item.MediaContent[0]}
                        propic={item.logo}
                        company_name={item.BrandName}
                        description = {item.CardDescription}
                        onClick={() => setSelectedEvent(item)}
                        profilePic = {item.Logo}
                        />
                    ))}
            </div>
            {selectedEvent 
                && 
                <EventExpansion
                    type="vendor_card" 
                    profilePic =  {selectedEvent.Logo}
                        title = {selectedEvent.title}
                        company_name = {selectedEvent.BrandName}
                        images = {selectedEvent.MediaContent}
                        category = {selectedEvent.CategoryDescription}
                        description = {selectedEvent.CardDescription}
                        likeCount = {selectedEvent.LikeCount}
                        comments  = {selectedEvent.Comments}
                        location = {selectedEvent.Location}
                    onClose={closeEventExpansion} 
                />
            }   
        </div>
    )
}