import React from "react";
import "./ProfileImage.css";
import user from "../../images/user.png"


export default function ProfileImage(props){
    return(
        <div className="profImage">
            <img 
                    src={props.img} 
                    alt="user"
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = user
                    }}
                />
        </div>
    )
}