import React from "react";
import './Cards.css'
import ProfileImage from "../ProfileImage/ProfileImage";

export default function Cards(props){
    const shortSentence = (sentence) => {
        if(!sentence){
            return '';
        }
        const words = sentence.split('')
        if(words.length > 50){
            return words.slice(0,50).join('') + '...';
        }
        return sentence;
    }
    return(
        <div className="card-container" onClick={props.onClick}>
            <div className="card-main">
                <div className="card-top">
                    <div className="top">
                        <div className="profilePic">
                            <ProfileImage img={props.profilePic ?props.profilePic: `https://picsum.photos/150/150?random=2`}/>
                        </div>
                        <p className="user-name">{props.company_name}</p>
                    </div>
                </div>
                <img className="main-pic" src={props.mainpic} alt="" />
            </div>
            <p className="desc">{shortSentence(props.description)}</p>
        </div>
    )
}