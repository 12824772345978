import React, { useEffect, useState } from "react";
import './SignUp.css'
import { BASE_URL } from "../../api/config";
import { GoogleLogin } from '@react-oauth/google';
import PNG from "../../images/signPNG.png"
import LoginInput from "../../input/loginInput/LoginInput";
import PasswordInput from "../../input/passwordInput/PasswordInput"
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function SignUpPage(){

    const [err_msg, setErr_msg] = useState('')
    const [fName , setFName] = useState('')
    const [lName, setLName] = useState('')
    const [emailPhone,setEmailPhone] = useState('')
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const navigate = useNavigate()

    // Name Validation
    const nameValidation = (name) => {
        if (name.length < 2){
            return "Name should be atleast 2 characters"
        }
        return ""
    }

    // Password Validation
    const validatePassword = (password) => {
        const minLength = 8;
        const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    
        if (password.length < minLength) {
            return "Password must be at least 8 characters long."
        }
        if (!regex.test(password)) {
            return "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
        }
        return ""

    }

    // Email Or Phone Validation
    const emailPhoneValidation = (value) => {
        const regex = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?![. ])|\+?\d{10})$/;
        if (!regex.test(value)) {
            return "Input must be a valid email address or a phone number."
        }
        return ""
    };

    const handleType = (e,validationFn, stateVar, setStateVar) => {
        setStateVar(e.target.value)
        const errorMessage = validationFn(e.target.value)
        setErr_msg(errorMessage)
    }

    // payload
    const payload = {
        email : emailPhone,
        password : password,
        fname:fName,
        lname:lName
    }

    // Form Submission
    const handleSubmit = async(e) => {
        e.preventDefault();

        const errorMessage = nameValidation(fName) || 
                             nameValidation(lName) || 
                             emailPhoneValidation(emailPhone) || 
                             validatePassword(password) || 
                             (password !== rePassword ? "Passwords do not match" : "")
        
        if (errorMessage) {
            setErr_msg(errorMessage);
            return
        } else {
            try{
                const res  = await axios.post(`${BASE_URL}/user_register`,payload,{
                    headers:{
                        "Content-Type":  'application/json'
                    }
                })
    
                if(res.status === 200){
                    console.log("Registration Success");
                    console.log({
                        email: emailPhone,
                        password: password,
                        name: fName + " " + lName
                    });
                    navigate('/Login')
                }else{
                    console.log("Registration Failed", res.status);
                }
            }catch(error){
                console.error('An error occurred',error)
            }
        }
    }



    return(
        <div className="sign-container">
            <div className="sign-main">
                <div className="left">
                    <h1>Create a New Account</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="name">
                            <LoginInput 
                                type = {`text`} 
                                label = {`First Name`}
                                value = {fName}
                                onChange = {(e) => handleType(e,nameValidation,fName,setFName)}
                            />

                            <LoginInput 
                                type = {`text`}
                                label = {`Last Name`}
                                value = {lName}
                                onChange = {(e) => handleType(e,nameValidation,lName,setLName)}
                            />
                        </div>
                        
                        <LoginInput
                            className="red" 
                            type = {`text`}
                            label = {`Email/Phone`}
                            value = {emailPhone}
                            onChange = {(e) => handleType(e,emailPhoneValidation,emailPhone,setEmailPhone)}
                            />

                        <PasswordInput
                            label = {`Enter Password`}
                            value = {password}
                            onChange = {(e) => handleType(e,validatePassword,password,setPassword)}

                        />
                        <PasswordInput
                            label = {`Re-Enter Password`}
                            value = {rePassword}
                            onChange = {(e) => handleType(e,validatePassword,rePassword,setRePassword)}
                        />

                        <div className="TnC">
                            <input type="checkbox" required/>
                            <p>By signing up you agree to our <a href="">Terms of Service</a> and <a href="">Privacy Policy</a></p>
                        </div>
                        <span className="err_msg">{err_msg}</span>
                        <button className="signUp-btn" type="submit">Sign Up</button>
                    </form>


                    <div className="separator"> 
                        <span>Or</span>
                    </div>
                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            console.log(credentialResponse)
                        }}
                        onError={() => {
                            console.log('Login Failed')
                        }}
                        shape={`circle`}
                        ux_mode="popup"
                        text={``}
                    />
                </div>
                <div className="right">
                    <img src={PNG} alt="" />
                    <h2>Already have an account?</h2>
                    <p>We're happy to have you back!</p>
                    <Link to ='/Login'>
                        <button className="login-btn">Login</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}