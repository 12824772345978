import React, { useEffect, useState } from "react";
import './Feed.css'
import FeedDetails from "../../../FrontPage/Feeds.json"
import Cards from "../../../../components/Cards/Cards";
import VendorPostForm from "../../../../modals/VendorPostForm/VendorPostForm";
import axios from "axios";
import { BASE_URL } from "../../../../api/config";
import EventExpansion from "../../../../modals/EventExpansion/EventExpansion";
let user_data = localStorage.getItem('user')
user_data = JSON.parse(user_data)
export default function Feed(){
    const [toggleForm, setToggleForm] = useState(false)
    const [feedData, setFeedData] = useState([])
    const [selectedFeed, setSelectedFeed] = useState(null)

    const token = localStorage.getItem('token')
    console.log(token)

    useEffect(() => {
        const getFeed = async() =>{
            try{
                let userData = localStorage.getItem('user')
                userData = JSON.parse(userData)
                const payload = {
                    "user_id" : userData.userid
                }
                const res = await axios.post(`${BASE_URL}/get_vendor_post`,payload,{
                    
                    headers:{
                        "Authorization" : `Bearer ${token}`
                    }
                })
                if(res.status === 200){
                    console.log('Feed',res.data)
                    setFeedData(res.data.data)
                }
            }catch(error){
                console.error('An error occured',error)
            }
        }
        getFeed()
    },[])


    return(
        <div className="feed-main">
             <div className="grid">
                <div className="upload-card" onClick={() => setToggleForm(!toggleForm)}>
                    <h2>Share your latest work</h2>
                    <p>Showcase your best, get feedback, and connect with a thriving community!</p>
                    <button>Upload</button>
                </div>
                {feedData.map((item) => {
                    
                    return(
                        
                        <Cards key={item.post_id}
                                mainpic={item.media_url[0]}
                                company_name={item.title}
                                description = {item.description}
                                onClick={() => setSelectedFeed(item)}
                                profilePic = {user_data.logo}
                        />

                    )
                })}
                {selectedFeed && 
                    <EventExpansion
                        type = "vendor_feed"
                        profilePic =  {selectedFeed.Logo}
                        title = {selectedFeed.title}
                        company_name = {null}
                        images = {selectedFeed.media_url}
                        category = {selectedFeed.description}
                        description = {selectedFeed.description}
                        likeCount = {selectedFeed.like_count}
                        comments  = {selectedFeed.comments}
                        onClose={() => setSelectedFeed(null)}
                    />
                }
            </div>
            {toggleForm && 
                <VendorPostForm
                    setToggleForm={setToggleForm}
                    toggleForm={toggleForm}
                />
            }

        </div>
    )
}