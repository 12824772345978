import React from "react";
import './Navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStumbleupon } from '@fortawesome/free-brands-svg-icons';
import { faLocationDot, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import UserCard from "../UserCard/UserCard";
import { useAuth } from "../../context/AuthContext";

export default function Navbar(props){

    const[location,setLocation] = React.useState("Fort Kochi")

    const {getUser} = useAuth()
    const user = getUser()
    
    return(
        <div className="nav-container">
            <div className="logo">
                <FontAwesomeIcon icon={faStumbleupon} shake/>
                <h1 className="blue"><strong>HAPVENT</strong></h1>
            </div>
            <div className="loc">
                <FontAwesomeIcon className="loc-icon" icon={faLocationDot}/>
                <h4>{location}</h4>
            </div>
            <div className="search">
                <form action="/search">
                    <input type="search" placeholder="What are you looking for?" />
                    <button type="submit"><FontAwesomeIcon className="sub-icon" icon={faMagnifyingGlass}/></button>
                </form>
            </div>
            {user ?
            <div className="btns">
                <UserCard 
                    user = {user}
                /> 
            </div>
            :
            <div className="btns">
                <Link to='/Vendorlogin'><button className="vendor-btn">I'm a vendor</button></Link>
                <Link to= '/Login'> <button className="login-btn">Login</button></Link>
            </div> 

            }

        </div>
    )
}