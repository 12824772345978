import React, { useState } from "react";
import './PasswordInput.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function PasswordInput(props){
    
    const[showPassword, setShowPassword] = useState(false)

    return(
        <div className="pass">
            <input type={showPassword ? "text" : "password"} 
                   value={props.value} 
                   onChange={props.onChange}
                   name={props.name}
                   maxLength={30} 
                   required
            />
            <label>{props.label}</label> 
            <FontAwesomeIcon 
                icon={showPassword ? faEyeSlash : faEye} 
                className="p-icon"
                onClick={() => setShowPassword(!showPassword)}
            />       
        </div>
    )
}